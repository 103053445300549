body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

iframe {
  width: calc(100vw - 0px);
  height: calc(100vh - 0px);
  overflow: hidden;
  overflow-x: hidden;
  outline: none;
  border: none;
  box-sizing: border-box;
}


/*
 *  STYLE 3
 */

 iframe::-webkit-scrollbar-track
 {
   -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
   background-color: #F5F5F5;
 }
 
 iframe::-webkit-scrollbar
 {
   width: 6px;
   background-color: #F5F5F5;
 }
 
 iframe::-webkit-scrollbar-thumb
 {
   background-color: #000000;
 }